/* eslint-disable @typescript-eslint/no-explicit-any */
import { BodyText, Heading } from "@lmig/lmds-react-typography";
import { FunctionComponent } from "react";
import strings from "../../shared/resources/strings.json";
import EnvironmentFacade from "../../environment/environment-facade";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import analyticsManager from "@lmig/crab-rangoons";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import { List, ListItem } from "@lmig/lmds-react-list";
import { Link as LmdsLink } from "@lmig/lmds-react";
import { Link, useParams } from "react-router-dom";

type ConfirmationProps = {};

interface RouteParams {
  email: string;
}

const sendConfirmationEvent = () =>
  analyticsManager.dispatchPageload({
    applicationID: "Management",
    siteSection: "Account",
    TileName: "registrationEmailSent",
    toolName: "Auth0 Registration",
    "confirm.service": "ServiceComplete",
    platformTrigram: EnvironmentFacade.getEnvironment().webView ? "ERA" : "ERW",
  });

export const Confirmation: FunctionComponent<ConfirmationProps> = () => {
  const { email } = useParams<RouteParams>();
  sendConfirmationEvent();
  return (
    <LocalizationProvider resource={strings} language="en">
      <Heading type="h1">
        <LocalizedText resourceKey="registration.confirmation.header" />
      </Heading>
      <BodyText
        style={{
          marginBottom: "1.5rem",
        }}
      >
        <p>
          <LocalizedText resourceKey="registration.confirmation.intro" />
          <b>{email}</b>.
        </p>
      </BodyText>
      <BodyText
        style={{
          marginBottom: "1.5rem",
        }}
      >
        <p>
          <LocalizedText resourceKey="registration.confirmation.action-1" />
          <b>
            <LocalizedText resourceKey="registration.confirmation.action-2" />
          </b>
          <LocalizedText resourceKey="registration.confirmation.action-3" />
        </p>
      </BodyText>
      <BodyText
        style={{
          marginBottom: ".5rem",
        }}
      >
        <p>
          <LocalizedText resourceKey="registration.confirmation.tips" />
        </p>
      </BodyText>
      <List compact>
        <ListItem>
          <LocalizedText resourceKey="registration.confirmation.tip-1" />
        </ListItem>
        <ListItem>
          <LocalizedText resourceKey="registration.confirmation.tip-2-1" />
          <b>
            <LocalizedText resourceKey="registration.confirmation.tip-2-2" />
          </b>
          <LocalizedText resourceKey="registration.confirmation.tip-2-3" />
          <b>
            <LocalizedText resourceKey="registration.confirmation.tip-2-4" />
          </b>
          <LocalizedText resourceKey="registration.confirmation.tip-2-5" />
          <LmdsLink
            href={"/registration" + location.search}
            custom={{ component: Link, mapHrefToProp: "to" }}
          >
            <LocalizedText resourceKey="registration.confirmation.tip-2-link" />
          </LmdsLink>
        </ListItem>
        <ListItem>
          <LocalizedText resourceKey="registration.confirmation.tip-3" />
        </ListItem>
      </List>
      <br />
      <br />
      <br />
    </LocalizationProvider>
  );
};
