import { datadogLogs } from "@datadog/browser-logs";
import { FormikBag } from "formik";
import { RouteComponentProps, StaticContext } from "react-router";
import ResetRequestService, { Email } from "./password-reset-request-service";

export async function resetPasswordRequest(
  values: Email,
  formikBag: FormikBag<RouteComponentProps<{}, StaticContext, unknown>, Email>,
) {
  try {
    await ResetRequestService.submit(values);

    formikBag.props.history.push(
      `/password-reset/confirmation/${values.email}${formikBag.props.location.search}`,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    datadogLogs.logger.error("Error requesting password reset", err);
    formikBag.props.history.push(
      "/password-reset/error" + formikBag.props.location.search,
    );
  }
}
