import { History } from "history";

export interface IQueryParamService {
  clearErrorParam(history: History): void;
}

class QueryParamService implements IQueryParamService {
  constructor() {}

  clearErrorParam = (history: History): void => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("error")) {
      params.delete("error");
      history.push(window.location.pathname + "?" + params.toString());
    }
  };
}

export default new QueryParamService();
