/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Component } from "react";
import { PasswordResetView } from "./PasswordResetView";
import { resetPasswordRequest } from "./helpers/password-reset-request";
import { Email } from "./helpers/password-reset-request-service";
import EnvironmentFacade from "../../environment/environment-facade";

type PasswordResetProps = FormikProps<Email> &
  RouteComponentProps &
  AnalyticsProps;

type AnalyticsProps = {
  analyticsManager: any;
};

type PasswordResetState = {
  ulaUrl: string;
  isLoading: boolean;
};

const ResetRequestValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address."),
});

export class PasswordResetRequestComponent extends Component<
  PasswordResetProps,
  PasswordResetState
> {
  constructor(props: PasswordResetProps) {
    super(props);
  }

  async componentDidMount() {
    this.props.analyticsManager.dispatchPageload({
      toolName: "Auth0 AccountRecovery",
      applicationID: "Management",
      siteSection: "Account",
      TileName: "recoveryRequest",
      "confirm.service": "ServiceStart",
      platformTrigram: EnvironmentFacade.getEnvironment().webView
        ? "ERA"
        : "ERW",
    });
  }
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dirty,
      isValid,
    } = this.props;

    return (
      <PasswordResetView
        onChange={handleChange}
        values={values}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        dirty={dirty}
        isValid={isValid}
      />
    );
  }
}

export default withRouter(
  withFormik<RouteComponentProps & AnalyticsProps, Email>({
    mapPropsToValues: () => {
      return {
        email: "",
      };
    },

    validationSchema: ResetRequestValidationSchema,
    handleSubmit: resetPasswordRequest,
  })(PasswordResetRequestComponent),
);
