/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ErrorInfo, ReactNode } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";

interface Props extends RouteComponentProps {
  children: ReactNode;
  redirectTo: string;
  workflow: string;
  analyticsManager: any;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogLogs.logger.error(error.message, {
      workflow: this.props.workflow,
      "error.kind": error.name,
      "error.stack": errorInfo.componentStack,
    });
    this.props.analyticsManager.dispatchPageload({
      contentType: "error",
      contentID: error.name,
    });
  }

  public render() {
    if (this.state.hasError) {
      return <Redirect to={this.props.redirectTo + location.search} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
