import { FormikBag } from "formik";
import { datadogLogs } from "@datadog/browser-logs";
import { RouteComponentProps, StaticContext } from "react-router";
import federationService, { FederatedService } from "./federationService";

export async function federationSubmit(
  values: FederatedService,
  formikBag: FormikBag<
    RouteComponentProps<{}, StaticContext, unknown>,
    FederatedService
  >,
) {
  try {
    federationService.submit(values);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    datadogLogs.logger.error("Error submitting federated email", err);
    formikBag.props.history.push("/error" + formikBag.props.location.search);
  }
}
