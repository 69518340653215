import { FunctionComponent } from "react";
import { BodyText, Heading } from "@lmig/lmds-react-typography";
import strings from "../../shared/resources/strings.json";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import { SkeletonState } from "@lmig/colossus-containers";
import { Button } from "@lmig/lmds-react-button";
import Styles from "./styles/authError.module.scss";
import { useThreshold } from "@lmig/lmds-react";
import Auth0Service from "../../shared/helpers/auth0-service";

type ErrorProps = {
  isLoading: boolean;
  errorDescription: string;
  isLinkExpired: boolean;
  blockedUser: boolean;
};

const largeSizes = ["lg", "xl"];

export const Auth0ErrorView: FunctionComponent<ErrorProps> = ({
  isLoading,
  errorDescription,
  isLinkExpired,
  blockedUser,
}) => {
  return (
    <LocalizationProvider resource={strings}>
      <Heading type="h1" className={Styles.heading}>
        <LocalizedText
          resourceKey={
            isLinkExpired
              ? "error.link-expired.header"
              : blockedUser
                ? "error.blocked.header"
                : "error.header"
          }
        />
      </Heading>
      <BodyText type="article" tag="h2" className={Styles.body}>
        <LocalizedText
          resourceKey={
            isLinkExpired
              ? "error.link-expired.message"
              : blockedUser
                ? "error.blocked.message"
                : "error.message"
          }
        />
        {blockedUser ? (
          <>
            <br />
            <LocalizedText resourceKey={"error.blocked.contact"} />
            <p className={Styles.authError}>{errorDescription}</p>
          </>
        ) : null}
      </BodyText>
      <br />
      {blockedUser ? null : (
        <SkeletonState
          show={isLoading}
          inline
          className={
            largeSizes.includes(useThreshold() || "base") && !isLinkExpired
              ? Styles.loading
              : null
          }
        >
          {isLinkExpired ? (
            <Button variant="primary" href="/password-reset">
              <LocalizedText resourceKey="error.link-expired.link-text" />
            </Button>
          ) : (
            <Button variant="primary" onClick={Auth0Service.redirectToLogin}>
              <LocalizedText resourceKey="error.login-link" />
            </Button>
          )}
        </SkeletonState>
      )}
      <br />
    </LocalizationProvider>
  );
};
