import accounts from "../../../shared/helpers/accounts.json";
import { Email, IResetRequestService } from "./password-reset-request-service";

const delay = 1000;

class MockResetRequestService implements IResetRequestService {
  submit(values: Email): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = accounts.filter((x) => x.email === values.email);
        if (item.length === 0) {
          reject("Error");
        }

        resolve("Success");
      }, delay);
    });
  }
}

export default new MockResetRequestService();
