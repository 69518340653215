import { useLayoutEffect } from "react";
import EnvironmentFacade from "../components/environment/environment-facade";

const getImagesByAlt = (alt: string): HTMLImageElement[] => {
  const allImages = document.getElementsByTagName("img");
  // eslint-disable-next-line prefer-const
  let images: HTMLImageElement[] = [];
  for (let i = 0, len = allImages.length; i < len; ++i) {
    if (allImages[i].alt == alt) {
      images.push(allImages[i]);
    }
  }
  return images;
};

const getGeicoLogoUrl = (): string => {
  switch (EnvironmentFacade.getEnvironment().envName) {
    case "local":
    case "development":
      return "https://digital-account-dev.assets.lmig.com/GEICO_Insurance_Agency_LLC_Logo.4d600e89.png";
    case "non-prod":
      return "https://digital-account-np.assets.lmig.com/GEICO_Insurance_Agency_LLC_Logo.4d600e89.png";
    case "production":
    default:
      return "https://digital-account.assets.libertymutual.com/GEICO_Insurance_Agency_LLC_Logo.4d600e89.png";
  }
};

function setGeicoLogoUrl(): void {
  setTimeout(() => {
    const cobrandingImages = getImagesByAlt("Geico Insurance Agency");
    if (Array.isArray(cobrandingImages) && cobrandingImages.length) {
      const image = cobrandingImages[0];
      image.src = getGeicoLogoUrl();
    }
  }, 0);
}

export function useUpdatedGeicoCobranding(): void {
  useLayoutEffect(() => {
    setGeicoLogoUrl();
  });
}
