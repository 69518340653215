/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Component } from "react";
import { FederationEmailView } from "./FederationEmailView";
import { FederatedService } from "./helpers/federationService";
import { federationSubmit } from "./helpers/federation-submit";

type UserRegistrationProps = FormikProps<FederatedService> &
  RouteComponentProps;

type UserRegistrationState = {
  ulaUrl: string;
  isLoading: boolean;
};

const RegistrationValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid")
    .required("Please enter a valid email address."),
  confirmEmail: Yup.string()
    .email("Email is invalid")
    .oneOf([Yup.ref("email"), null], "Emails do not match")
    .required("Please enter a valid email address."),
});
export class FederationEmailComponent extends Component<
  UserRegistrationProps,
  UserRegistrationState
> {
  constructor(props: UserRegistrationProps) {
    super(props);
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dirty,
      isValid,
    } = this.props;

    return (
      <FederationEmailView
        onChange={handleChange}
        values={values}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        dirty={dirty}
        isValid={isValid}
      />
    );
  }
}

export default withRouter(
  withFormik<RouteComponentProps, FederatedService>({
    mapPropsToValues: () => {
      return {
        email: "",
        confirmEmail: "",
      };
    },

    validationSchema: RegistrationValidationSchema,
    handleSubmit: federationSubmit,
  })(FederationEmailComponent),
);
