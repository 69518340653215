import { BodyText, Heading } from "@lmig/lmds-react-typography";
import { FunctionComponent } from "react";
import strings from "../../shared/resources/strings.json";
import EnvironmentFacade from "../../environment/environment-facade";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import analyticsManager from "@lmig/crab-rangoons";
import { List, ListItem } from "@lmig/lmds-react-list";
import { Link as LmdsLink } from "@lmig/lmds-react";
import { Link, useParams } from "react-router-dom";

type ConfirmationProps = {};

interface RouteParams {
  email: string;
}

const sendPasswordResetConfirmationEvent = () =>
  analyticsManager.dispatchPageload({
    toolName: "Auth0 AccountRecovery",
    applicationID: "Management",
    siteSection: "Account",
    TileName: "recoveryEmailRequest",
    "confirm.service": "ServiceComplete",
    platformTrigram: EnvironmentFacade.getEnvironment().webView ? "ERA" : "ERW",
  });

export const PasswordResetRequestConfirmation: FunctionComponent<
  ConfirmationProps
> = () => {
  const { email } = useParams<RouteParams>();
  sendPasswordResetConfirmationEvent();
  return (
    <LocalizationProvider resource={strings}>
      <Heading type="h1">
        <LocalizedText resourceKey="password-reset.confirmation.header" />
      </Heading>
      <BodyText>
        <p>
          <LocalizedText resourceKey="password-reset.confirmation.intro" />
          <b>{email}</b>
        </p>
      </BodyText>
      <BodyText
        style={{
          marginBottom: ".5rem",
        }}
      >
        <p>
          <LocalizedText resourceKey="password-reset.confirmation.action-1" />
          <b>
            <LocalizedText resourceKey="password-reset.confirmation.action-bold" />
          </b>
          <LocalizedText resourceKey="password-reset.confirmation.action-2" />
        </p>
      </BodyText>
      <BodyText>
        <p
          style={{
            marginBottom: ".5rem",
          }}
        >
          <LocalizedText resourceKey="password-reset.confirmation.tips" />
        </p>
      </BodyText>
      <List compact>
        <ListItem>
          <LocalizedText resourceKey="password-reset.confirmation.tip-1" />
        </ListItem>
        <ListItem>
          <LocalizedText resourceKey="password-reset.confirmation.tip-2" />
          <LmdsLink
            href={"/password-reset" + location.search}
            custom={{ component: Link, mapHrefToProp: "to" }}
          >
            <LocalizedText resourceKey="password-reset.confirmation.tip-2-link" />
          </LmdsLink>
        </ListItem>
        <ListItem>
          <LocalizedText resourceKey="password-reset.confirmation.tip-3" />
          <LmdsLink
            href={"/registration" + location.search}
            custom={{ component: Link, mapHrefToProp: "to" }}
          >
            <LocalizedText resourceKey="password-reset.confirmation.tip-3-link" />
          </LmdsLink>
        </ListItem>
        <ListItem>
          <LocalizedText resourceKey="password-reset.confirmation.tip-4" />
        </ListItem>
      </List>
      <br />
      <br />
      <br />
    </LocalizationProvider>
  );
};
