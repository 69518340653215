import accounts from "../../../shared/helpers/accounts.json";
import {
  createModel,
  IRegistrationService,
  UserRegistration,
} from "./registration-service";

const delay = 1000;

class MockRegistrationService implements IRegistrationService {
  submit(values: UserRegistration): Promise<string> {
    const model = createModel(values);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = accounts.filter((x) => x.email === model.email);
        if (item.length === 0) {
          reject("Error");
        }

        resolve("Success");
      }, delay);
    });
  }
}

export default new MockRegistrationService();
