import {
  Form,
  AlphanumericInput,
  Button,
  FieldGroup,
  GridCol,
  GridRow,
  Heading,
  Link,
  BodyText,
} from "@lmig/lmds-react";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import strings from "../../shared/resources/strings.json";
import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { FormikErrors, FormikTouched } from "formik";
import { Email } from "./helpers/password-reset-request-service";
import { History } from "history";
// import { notifications } from "../../shared/helpers/notifications";

type PasswordResetViewProps = {
  isSubmitting: boolean;
  touched: FormikTouched<Email>;
  errors: FormikErrors<Email>;
  values: Email;
  onChange: (e: ChangeEvent<unknown>) => void;
  onBlur: (e: ChangeEvent<unknown>) => void;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  dirty: boolean;
  isValid: boolean;
};

class ErrorMessage {
  text: string;
  // a workaround due to an oddity in the definition for this prop in lmds
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  type: "error" = "error";

  constructor(message: string) {
    this.text = message;
  }
}

const getMessages = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return [];
  }
  return [new ErrorMessage(error)];
};

const getHighlight = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return undefined;
  }
  return "error";
};

export const PasswordResetView: FunctionComponent<PasswordResetViewProps> = ({
  isSubmitting,
  values,
  onChange,
  onBlur,
  onSubmit,
  touched,
  errors,
  dirty,
  isValid,
}) => {
  const history: History = useHistory();
  return (
    <>
      <LocalizationProvider resource={strings}>
        {/* {notifications("password-reset")} */}
        <br />
        <Link
          caretPosition="left"
          onClick={history.goBack}
          style={{
            marginTop: "3rem",
          }}
          variant="standalone"
        >
          <LocalizedText resourceKey="password-reset.return-link" />
        </Link>
        <Form onSubmit={onSubmit}>
          <GridRow gutters>
            <GridCol sm={12} md={12}>
              <Heading type="h3">
                <LocalizedText resourceKey="password-reset.header.title" />
              </Heading>
              <BodyText>
                <p>
                  <LocalizedText resourceKey="password-reset.body.text" />
                </p>
              </BodyText>
            </GridCol>
          </GridRow>
          <GridRow gutters>
            <GridCol sm={12} md={4}>
              <FieldGroup
                disableMargins="all"
                messagesRole="alert"
                messages={getMessages(touched.email, errors.email)}
              >
                <AlphanumericInput
                  name="email"
                  highlightType={getHighlight(touched.email, errors.email)}
                  id="email"
                  labelVisual={
                    <LocalizedText resourceKey="password-reset.body.form.labels.email" />
                  }
                  value={values.email}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: ".5rem",
                  }}
                />
              </FieldGroup>
            </GridCol>
          </GridRow>
          <GridRow gutters>
            <GridCol sm={12} md={4}>
              <Button
                disabled={isSubmitting || !(dirty && isValid)}
                variant="primary"
                dynamicWidth
                type="submit"
              >
                <LocalizedText resourceKey="password-reset.body.form.labels.submit" />
              </Button>
            </GridCol>
          </GridRow>
          <GridRow gutters>
            <GridCol base={4}>
              <Link
                caretPosition="none"
                href="https://libertymutualgroup.com/about-lm/corporate-information/privacy-policy"
                variant="inline"
                style={{
                  float: "left",
                  marginTop: ".25rem",
                }}
              >
                <LocalizedText resourceKey="password-reset.body.form.labels.privacy-policy" />
              </Link>
            </GridCol>
          </GridRow>
        </Form>
      </LocalizationProvider>
    </>
  );
};
