import { Component } from "react";
import { Auth0ErrorView } from "./Auth0ErrorView";
import { datadogLogs } from "@datadog/browser-logs";
import queryParameterService from "../../shared/helpers/query-parameter-service";
import { History } from "history";

type ErrorPageProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsManager: any;
  history: History;
};

type State = {
  isLoading: boolean;
  errorDescription: string;
  linkExpired: boolean;
  userBlocked: boolean;
  errorQueryParam: string;
};

export class Auth0ErrorComponent extends Component<ErrorPageProps, State> {
  constructor(props: ErrorPageProps) {
    super(props);
    this.state = {
      isLoading: true,
      errorDescription:
        new URLSearchParams(window.location.search).get("error_description") ||
        "",
      linkExpired:
        new URLSearchParams(window.location.search).get("error_description") ===
          "Access expired." || false,
      userBlocked:
        new URLSearchParams(window.location.search).get("msg") === "blk" ||
        false,
      errorQueryParam:
        new URLSearchParams(window.location.search).get("error") || "",
    };
  }

  async componentDidMount() {
    if (this.state.userBlocked) {
      datadogLogs.logger.log("blocked user hit error page", {
        workflow: "auth0-generic-blocked-error-page",
      });
    } else if (this.state.errorQueryParam !== "") {
      datadogLogs.logger.error(this.state.errorDescription, {
        workflow: "auth0-generic-error-page",
        "error.kind": this.state.errorQueryParam,
        "error.queryParams": location.search,
      });
      queryParameterService.clearErrorParam(this.props.history);
      this.setState({ errorQueryParam: "" });
    } else {
      datadogLogs.logger.error(this.state.errorDescription, {
        workflow: "auth0-generic-error-page",
      });
    }

    this.props.analyticsManager.dispatchPageload({
      contentType: "error",
      contentID: "Auth0 Error",
    });

    this.setState({
      isLoading: this.state.errorQueryParam !== "",
    });
  }

  render() {
    return (
      <Auth0ErrorView
        isLoading={this.state.isLoading}
        errorDescription={this.state.errorDescription}
        isLinkExpired={this.state.linkExpired}
        blockedUser={this.state.userBlocked}
      />
    );
  }
}
