import { Component } from "react";
import Auth0Service from "../../shared/helpers/auth0-service";

type State = {
  ulaUrl: string;
  isLoading: boolean;
};

export class Homepage extends Component<{}, State> {
  constructor() {
    super({});
    this.state = { ulaUrl: "", isLoading: true };
  }

  async componentDidMount() {
    await Auth0Service.setLoginUrl();
    this.setState({ ulaUrl: Auth0Service.ulaUrl, isLoading: false });
  }

  render() {
    return (
      <>
        {this.state.isLoading
          ? null
          : window.location.replace(
              this.state.ulaUrl + "&" + location.search.substring(1),
            )}
      </>
    );
  }
}
