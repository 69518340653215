import {
  Form,
  AlphanumericInput,
  Button,
  FieldGroup,
  GridCol,
  GridRow,
  Heading,
  BodyText,
} from "@lmig/lmds-react";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import strings from "../../shared/resources/strings.json";
import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { FederatedService } from "./helpers/federationService";
import styles from "./styles/federation.module.scss";

type RegistrationViewProps = {
  isSubmitting: boolean;
  touched: FormikTouched<FederatedService>;
  errors: FormikErrors<FederatedService>;
  values: FederatedService;
  dirty: boolean;
  isValid: boolean;
  onChange: (e: ChangeEvent<unknown>) => void;
  onBlur: (e: ChangeEvent<unknown>) => void;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
};

class ErrorMessage {
  text: string;
  // a workaround due to an oddity in the definition for this prop in lmds
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  type: "error" = "error";

  constructor(message: string) {
    this.text = message;
  }
}

const getMessages = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return [];
  }
  return [new ErrorMessage(error)];
};

const getHighlight = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return undefined;
  }
  return "error";
};

export const FederationEmailView: FunctionComponent<RegistrationViewProps> = ({
  isSubmitting,
  values,
  onChange,
  onBlur,
  onSubmit,
  touched,
  errors,
  isValid,
  dirty,
}) => {
  return (
    <LocalizationProvider resource={strings}>
      <Form onSubmit={onSubmit}>
        <GridRow gutters>
          <GridCol sm={12} md={8}>
            <Heading type="h1">
              <LocalizedText resourceKey="federation.header" />
            </Heading>
            <BodyText>
              <LocalizedText resourceKey="federation.message" />
            </BodyText>
          </GridCol>
        </GridRow>
        <GridRow gutters="horizontal" vAlign="bottom">
          <GridCol sm={12} md={4} className={styles.gridCol}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.email, errors.email)}
            >
              <AlphanumericInput
                id="email"
                highlightType={getHighlight(touched.email, errors.email)}
                name="email"
                labelVisual={
                  <LocalizedText resourceKey="federation.form.email" />
                }
                value={values.email}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
        </GridRow>
        <GridRow gutters="horizontal" vAlign="bottom">
          <GridCol sm={12} md={4} className={styles.gridCol}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.confirmEmail, errors.confirmEmail)}
            >
              <AlphanumericInput
                name="confirmEmail"
                highlightType={getHighlight(
                  touched.confirmEmail,
                  errors.confirmEmail,
                )}
                labelVisual={
                  <LocalizedText resourceKey="federation.form.confirmEmail" />
                }
                value={values.confirmEmail}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
        </GridRow>
        <GridRow gutters="horizontal">
          <GridCol sm={12} md={4}>
            <Button
              disabled={isSubmitting || !(dirty && isValid)}
              dynamicWidth
              variant="primary"
              type="submit"
              className={styles.submitButton}
            >
              <LocalizedText resourceKey="federation.form.submit" />
            </Button>
          </GridCol>
        </GridRow>
      </Form>
    </LocalizationProvider>
  );
};
