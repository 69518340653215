import { FunctionComponent } from "react";
import { BodyText, Heading } from "@lmig/lmds-react-typography";
import strings from "./resources/strings.json";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import { Content } from "@lmig/lmds-react-content";
import styles from "./styles/Splash.module.scss";
import { useUpdatedGeicoCobranding } from "../../../hooks/geicoCobranding";

// eslint-disable-next-line @typescript-eslint/ban-types
export const Splash: FunctionComponent<{}> = () => {
  useUpdatedGeicoCobranding();
  return (
    <LocalizationProvider resource={strings}>
      <Content className={styles.content}>
        <Heading type="h1">
          <LocalizedText resourceKey="splash.header" />
        </Heading>
        <BodyText>
          <p>
            <LocalizedText resourceKey="splash.message" />
          </p>
        </BodyText>
      </Content>
    </LocalizationProvider>
  );
};
