import axios from "axios";
import EnvironmentFacade from "../../../environment/environment-facade";
import MockResetRequestService from "./mock-reset-request-service";

export interface Email {
  email: string;
}

export interface IResetRequestService {
  submit(values: Email): Promise<string>;
}

class ResetRequestService implements IResetRequestService {
  submit(values: Email): Promise<string> {
    const environment = EnvironmentFacade.getEnvironment();

    if (environment.envName === "local") {
      return MockResetRequestService.submit(values);
    } else {
      return axios.post(
        `${environment.apiUrl}account/${values.email}/password/reset`,
      );
    }
  }
}

export default new ResetRequestService();
