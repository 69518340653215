/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { RegistrationView } from "./RegistrationView";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Component } from "react";
import { UserRegistration } from "./helpers/registration-service";
import { registrationSubmit } from "../src/helpers/registration-submit";
import EnvironmentFacade from "../../environment/environment-facade";

type UserRegistrationProps = FormikProps<UserRegistration> &
  RouteComponentProps &
  AnalyticsProps;

type AnalyticsProps = {
  analyticsManager: any;
};

type UserRegistrationState = {
  isLoading: boolean;
};

const RegistrationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Please enter a valid email address."),
  dob: Yup.string()
    .required(
      "Please enter your date of birth in the following format: MM/DD/YYYY.",
    )
    .matches(
      /^(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.]((?:19|20)\d\d)$/,
      {
        excludeEmptyString: true,
        message: "Date is invalid",
      },
    ),
  policyNumber: Yup.string()
    .required(
      "Please enter a policy number associated with this account. (eg: AO2-123-123456-11)",
    )
    .matches(/^[a-zA-Z0-9-]+$/, {
      excludeEmptyString: true,
      message: "Policy number can only contain numbers, letters, or hyphens.",
    })
    .matches(/^(?![a,A]0.*)/, {
      excludeEmptyString: true,
      message:
        "Enter a valid policy number. Auto policies start with letters “AO” not zero.",
    })
    .matches(/^\w{3}-?\w{3}-?\w{6}-?\w{2}$/, {
      excludeEmptyString: true,
      message: "Policy numbers have 14 characters, excluding hyphens.",
    }),
});

export class RegistrationComponent extends Component<
  UserRegistrationProps,
  UserRegistrationState
> {
  constructor(props: UserRegistrationProps) {
    super(props);
  }

  async componentDidMount() {
    this.props.analyticsManager.dispatchPageload({
      applicationID: "Management",
      siteSection: "Account",
      siteCode: "PM-ES",
      TileName: "registrationRequest",
      toolName: "Auth0 Registration",
      "confirm.service": "ServiceStart",
      platformTrigram: EnvironmentFacade.getEnvironment().webView
        ? "ERA"
        : "ERW",
    });
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <RegistrationView
        onChange={handleChange}
        values={values}
        onBlur={handleBlur}
        touched={touched}
        errors={errors}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    );
  }
}

export default withRouter(
  withFormik<AnalyticsProps & RouteComponentProps, UserRegistration>({
    mapPropsToValues: () => {
      return {
        requestSource:
          new URLSearchParams(window.location.search).get("source") ===
          "libertyplus"
            ? "liberty_plus"
            : "self-directed",
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        policyNumber: "",
      };
    },

    validationSchema: RegistrationValidationSchema,
    handleSubmit: registrationSubmit,
  })(RegistrationComponent),
);
