import {
  Form,
  AlphanumericInput,
  Button,
  FieldGroup,
  GridCol,
  GridRow,
  Heading,
  Link,
  Caption,
  BodyText,
} from "@lmig/lmds-react";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import strings from "../../shared/resources/strings.json";
import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { UserRegistration } from "./helpers/registration-service";
import Auth0Service from "../../shared/helpers/auth0-service";
// import { notifications } from "../../shared/helpers/notifications";

type RegistrationViewProps = {
  isSubmitting: boolean;
  touched: FormikTouched<UserRegistration>;
  errors: FormikErrors<UserRegistration>;
  values: UserRegistration;
  onChange: (e: ChangeEvent<unknown>) => void;
  onBlur: (e: ChangeEvent<unknown>) => void;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
};

class ErrorMessage {
  text: string;
  // a workaround due to an oddity in the definition for this prop in lmds
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  type: "error" = "error";

  constructor(message: string) {
    this.text = message;
  }
}

const getMessages = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return [];
  }
  return [new ErrorMessage(error)];
};

const getHighlight = (
  touched: boolean | undefined,
  error: string | undefined,
) => {
  if (!touched || !error) {
    return undefined;
  }
  return "error";
};

export const RegistrationView: FunctionComponent<RegistrationViewProps> = ({
  isSubmitting,
  values,
  onChange,
  onBlur,
  onSubmit,
  touched,
  errors,
}) => {
  return (
    <LocalizationProvider resource={strings}>
      {/* {notifications("registration")} */}
      <Form onSubmit={onSubmit}>
        <GridRow gutters>
          <GridCol sm={12} md={12}>
            <Heading type="h1">
              <LocalizedText resourceKey="registration.header.title" />
            </Heading>

            <Heading type="h4-light">
              <LocalizedText resourceKey="registration.header.subtitle" />
            </Heading>
          </GridCol>
        </GridRow>
        <GridRow gutters="horizontal" vAlign="bottom">
          <GridCol sm={6} md={4}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.firstName, errors.firstName)}
            >
              <AlphanumericInput
                id="firstName"
                highlightType={getHighlight(
                  touched.firstName,
                  errors.firstName,
                )}
                name="firstName"
                labelVisual={
                  <LocalizedText resourceKey="registration.form.labels.firstName" />
                }
                value={values.firstName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
          <GridCol sm={6} md={4}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.lastName, errors.lastName)}
            >
              <AlphanumericInput
                name="lastName"
                highlightType={getHighlight(touched.lastName, errors.lastName)}
                labelVisual={
                  <LocalizedText resourceKey="registration.form.labels.lastName" />
                }
                value={values.lastName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
        </GridRow>
        <GridRow gutters vAlign="bottom">
          <GridCol sm={12} md={4}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.dob, errors.dob)}
            >
              <AlphanumericInput
                name="dob"
                highlightType={getHighlight(touched.dob, errors.dob)}
                labelVisual={
                  <LocalizedText resourceKey="registration.form.labels.dob" />
                }
                mask="date"
                value={values.dob}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
          <GridCol sm={12} md={4}>
            <FieldGroup
              disableMargins="all"
              messagesRole="alert"
              messages={getMessages(touched.email, errors.email)}
            >
              <AlphanumericInput
                name="email"
                highlightType={getHighlight(touched.email, errors.email)}
                id="email"
                labelVisual={
                  <LocalizedText resourceKey="registration.form.labels.email" />
                }
                value={values.email}
                onChange={onChange}
                onBlur={onBlur}
              />
            </FieldGroup>
          </GridCol>
        </GridRow>
        <GridRow gutters="horizontal" vAlign="bottom">
          <GridCol sm={12} md={4}>
            <FieldGroup
              disableMargins="top"
              messagesRole="alert"
              messages={getMessages(touched.policyNumber, errors.policyNumber)}
            >
              <AlphanumericInput
                name="policyNumber"
                highlightType={getHighlight(
                  touched.policyNumber,
                  errors.policyNumber,
                )}
                labelVisual={
                  <LocalizedText resourceKey="registration.form.labels.policyNumber" />
                }
                value={values.policyNumber}
                onChange={onChange}
                onBlur={onBlur}
              />
              <Caption>
                <LocalizedText resourceKey="registration.form.labels.policyTip" />
              </Caption>
            </FieldGroup>
          </GridCol>
        </GridRow>
        <br />
        <GridRow gutters>
          <GridCol sm={12} md={4}>
            <Button
              disabled={
                !Object.keys(touched).length ||
                isSubmitting ||
                Object.keys(errors).length > 0
              }
              dynamicWidth
              variant="primary"
              type="submit"
            >
              <LocalizedText resourceKey="registration.form.labels.submit" />
            </Button>
          </GridCol>
        </GridRow>
      </Form>
      <GridRow gutters>
        <GridCol sm={12} md={12}>
          <BodyText>
            <p>
              <LocalizedText resourceKey="registration.existingCustomer" />
              <Link onClick={Auth0Service.redirectToLogin} role="link">
                <LocalizedText resourceKey="registration.loginText" />
              </Link>
            </p>
          </BodyText>
        </GridCol>
      </GridRow>
    </LocalizationProvider>
  );
};
