import { datadogLogs } from "@datadog/browser-logs";
import { FormikBag } from "formik";
import { RouteComponentProps, StaticContext } from "react-router";
import RegistrationService, { UserRegistration } from "./registration-service";

export async function registrationSubmit(
  values: UserRegistration,
  formikBag: FormikBag<
    RouteComponentProps<{}, StaticContext, unknown>,
    UserRegistration
  >,
) {
  try {
    await RegistrationService.submit(values);

    formikBag.props.history.push(
      `/registration/confirmation/${values.email}${formikBag.props.location.search}`,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    datadogLogs.logger.error("Error submitting registration", err);
    formikBag.props.history.push(
      "/registration/error" + formikBag.props.location.search,
    );
  }
}
