export type Environment = {
  apiUrl: string;
  envName: string;
  webView: boolean;
  auth0Domain: string;
  auth0ClientId: string;
  auth0RedirectUrl: string;
};
class EnvironmentFacade {
  env: Environment = {
    apiUrl: "",
    webView: false,
    envName: "local",
    auth0Domain: "login-dev.libertymutual.com",
    auth0ClientId: "BsSGFlyLdtyVHA4nvF8OsJgIIR1JD9WY",
    auth0RedirectUrl:
      "https://digital-account-test-dev.libertymutual.com/login",
  };
  getEnvironment = (url?: string): Environment => {
    if (!url && !window.location) {
      return this.env;
    }

    if (!url) {
      url = window.location.href;
    }

    const segment = url.split(".")[0];
    const userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (!safari) {
        this.env.webView = true;
      }
    } else {
      if (userAgent.includes("wv")) {
        this.env.webView = true;
      }
    }

    if (url.indexOf("digital-account.libertymutual.com") >= 0) {
      this.env.apiUrl = "https://account.eservice.libertymutual.com/";
      this.env.envName = "production";
      this.env.auth0Domain = "login.libertymutual.com";
      this.env.auth0ClientId = "lRTuc9gaQccFGP0BR5qGweP9A0bnE8ky";
      this.env.auth0RedirectUrl =
        "https://eservice.libertymutual.com/account/home";
    }

    if (segment.includes("-dev")) {
      this.env.apiUrl = "https://account.dev-eservice.libertymutual.com/";
      this.env.envName = "development";
      this.env.auth0RedirectUrl =
        "https://digital-account-test-dev.libertymutual.com/login";
    }
    if (segment.includes("-np")) {
      this.env.apiUrl = "https://account.np-eservice.libertymutual.com/";
      this.env.envName = "non-prod";
      this.env.auth0Domain = "login-np.libertymutual.com";
      this.env.auth0ClientId = "CjWb3FuXfTsY2FKHfHACft72pfkPYkHj";
      this.env.auth0RedirectUrl =
        "https://ete-eservice.libertymutual.com/account/home";
    }
    return this.env;
  };
}

export default new EnvironmentFacade();
