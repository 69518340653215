import { useLocation } from "react-router-dom";

export function useAffiliate(): { isLiberty: boolean; isGeico: boolean } {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const webId = params.get("MM_webID");
  const isGeico = webId === "0000006823";
  const isLiberty = !isGeico;
  return { isLiberty: isLiberty, isGeico: isGeico };
}
