import EnvironmentFacade from "../../../environment/environment-facade";

export type FederatedService = {
  email: string;
  confirmEmail: string;
};

export interface IFederationService {
  submit(values: FederatedService): void;
}

class FederationService implements IFederationService {
  submit(values: FederatedService): void {
    const environment = EnvironmentFacade.getEnvironment();

    window.location.replace(
      "https://" +
        environment.auth0Domain +
        "/continue" +
        window.location.search +
        "&newEmail=" +
        values.email,
    );
  }
}

export default new FederationService();
