import { BrowserRouter } from "react-router-dom";
import { FunctionComponent } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import analyticsManager from "@lmig/crab-rangoons";
import { MasterLayout } from "./components/layout/src/MasterLayout";
import EnvironmentFacade from "./components/environment/environment-facade";

type AppProps = {};

const isProd = EnvironmentFacade.getEnvironment().envName === "production";
const galileoUrl = isProd
  ? "https://galileo.libertymutual.com/v1/apievent"
  : "https://dev.galileo.libertymutual.com/v1/apievent";
const tealiumUrl = `https://tags.tiqcdn.com/utag/libertymutual/service/${
  isProd ? "prod" : "dev"
}/utag.js`;

export const App: FunctionComponent<AppProps> = () => {
  analyticsManager.init(null, {
    loggerBaseUrl: "",
    forceTealium: true,
    logFunc: console,
    galileoUrl: galileoUrl,
    tealiumUrl: tealiumUrl,
    isProd: isProd,
  });
  return (
    <BrowserRouter>
      <MasterLayout analyticsManager={analyticsManager} />
    </BrowserRouter>
  );
};

export default App;
