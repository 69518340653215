import { AuthClient } from "@lmig/dsc-auth0-token-manager";
import environmentFacade from "../../../components/environment/environment-facade";
import { RedirectLoginOptions } from "@lmig/dsc-auth0-token-manager/lib/auth-model";
import { CacheStoreLocation } from "@lmig/dsc-auth0-token-manager/lib/IAuthOptions";

const INACTIVITY_TIMEOUT_DURATION = 1200; // 20 minutes
const REFRESH_DURATION_OFFSET = 180; // 3 minutes

export interface IAuth0Service {
  ulaUrl: string;
  authClient: AuthClient;
  setLoginUrl(): Promise<string>;
  redirectToLogin(): Promise<void>;
}

class Auth0Service implements IAuth0Service {
  ulaUrl = "";
  authClient = new AuthClient({
    domain: "login-dev.libertymutual.com",
    clientId: "BsSGFlyLdtyVHA4nvF8OsJgIIR1JD9WY",
    redirectUri: "http://localhost:3000",
    scope: "openid profile email offline_access",
    refreshDurationOffset: REFRESH_DURATION_OFFSET,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: INACTIVITY_TIMEOUT_DURATION,
    enableIdleTimeout: true,
    ignoreCacheOnRefresh: true,
    useRefreshTokens: true,
    cacheLocation: "localstorage" as CacheStoreLocation,
  });

  constructor() {
    const environment = environmentFacade.getEnvironment();
    this.authClient = new AuthClient({
      domain: environment.auth0Domain.toString(),
      clientId: environment.auth0ClientId,
      redirectUri: environment.auth0RedirectUrl,
      scope: "openid profile email offline_access",
      refreshDurationOffset: REFRESH_DURATION_OFFSET,
      enableAutomaticTokenRefresh: true,
      inactivityTimeoutDuration: INACTIVITY_TIMEOUT_DURATION,
      enableIdleTimeout: true,
      useRefreshTokens: true,
      cacheLocation: "localstorage" as CacheStoreLocation,
    });
  }

  async setLoginUrl(): Promise<string> {
    await this.authClient.init();
    const url = await this.authClient.generateUlaUrl();
    this.ulaUrl = url;
    return url;
  }

  async redirectToLogin(): Promise<void> {
    const environment = environmentFacade.getEnvironment();

    const authClient = new AuthClient({
      domain: environment.auth0Domain.toString(),
      clientId: environment.auth0ClientId,
      redirectUri: environment.auth0RedirectUrl,
      scope: "openid profile email offline_access",
      refreshDurationOffset: REFRESH_DURATION_OFFSET,
      enableAutomaticTokenRefresh: true,
      inactivityTimeoutDuration: INACTIVITY_TIMEOUT_DURATION,
      enableIdleTimeout: true,
      useRefreshTokens: true,
      cacheLocation: "localstorage" as CacheStoreLocation,
    });

    const loginRedirectOptions: RedirectLoginOptions = {
      redirectUri: environment.auth0RedirectUrl,
    };
    await authClient.init();
    await authClient.loginWithRedirect(loginRedirectOptions);
  }
}

export default new Auth0Service();
