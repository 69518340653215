import { FunctionComponent } from "react";
import { BodyText, Heading } from "@lmig/lmds-react-typography";
import strings from "../../shared/resources/strings.json";
import {
  LocalizationProvider,
  LocalizedText,
} from "@lmig/colossus-localization";
import { Button } from "@lmig/lmds-react-button";
import { Link, useLocation } from "react-router-dom";

type ErrorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsManager: any;
};

export const PasswordResetRequestError: FunctionComponent<ErrorProps> = (
  props,
) => {
  const location = useLocation();
  props.analyticsManager.dispatchPageload({
    contentType: "error",
    contentID: "Password Reset Error",
  });
  return (
    <LocalizationProvider resource={strings}>
      <Heading type="h1">
        <LocalizedText resourceKey="error.header" />
      </Heading>
      <BodyText>
        <p>
          <LocalizedText resourceKey="error.message" />
        </p>
      </BodyText>
      <br />
      <Button
        variant="primary"
        href={"/password-reset" + location.search}
        custom={{ component: Link, mapHrefToProp: "to" }}
      >
        <LocalizedText resourceKey="error.link" />
      </Button>
      <br />
    </LocalizationProvider>
  );
};
