import { FunctionComponent } from "react";
import styles from "./styles/global.module.scss";
import { Content } from "@lmig/lmds-react";
import { Menu } from "@lmig/colossus-menu";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Confirmation as RegistrationConfirmation } from "../../registration/src/Confirmation";
import { ConfirmationGeico } from "../../registration/src/ConfirmationGeico";
import { PasswordResetRequestConfirmation } from "../../password-reset/src/PasswordResetRequestConfirmation";
import { RegistrationError } from "../../registration/src/RegistrationError";
import ErrorBoundary from "./ErrorBoundary";
import RegistrationComponent from "../../registration/src/RegistrationComponent";
import PasswordResetRequestComponent from "../../password-reset/src/PasswordResetRequestComponent";
import { PasswordResetRequestError } from "../../password-reset/src/PasswordResetRequestError";
import { Auth0ErrorComponent } from "../../auth0-error/src/Auth0ErrorComponent";
import { Homepage } from "../../hompage/src/Homepage";
import { useAffiliate } from "../../../hooks/useAffiliate";
import FederationEmailComponent from "../../federation/src/FederationEmailComponent";
import { Splash } from "../../splash/src/Splash";
import { History } from "history";

type MasterLayoutProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsManager: any;
};

export const MasterLayout: FunctionComponent<MasterLayoutProps> = ({
  analyticsManager,
}) => {
  const { isGeico } = useAffiliate();
  const history: History = useHistory();

  return (
    <div id="app" className={styles.app}>
      <Menu isTransactional useDefaultMenu>
        <Content id="content" className={styles.content}>
          <Switch>
            <ErrorBoundary
              redirectTo="/registration/error"
              workflow="registration"
              analyticsManager={analyticsManager}
            >
              <Route exact path="/">
                <Redirect to={"/registration" + location.search} />
              </Route>
              <Route exact path="/registration">
                <RegistrationComponent analyticsManager={analyticsManager} />
              </Route>
              <Route exact path="/registration/confirmation/:email">
                {isGeico ? <ConfirmationGeico /> : <RegistrationConfirmation />}
              </Route>
            </ErrorBoundary>
          </Switch>
          <Switch>
            <ErrorBoundary
              redirectTo="/password-reset/error"
              workflow="password-reset"
              analyticsManager={analyticsManager}
            >
              <Route exact path="/password-reset">
                <PasswordResetRequestComponent
                  analyticsManager={analyticsManager}
                />
              </Route>
              <Route exact path="/password-reset/confirmation/:email">
                <PasswordResetRequestConfirmation />
              </Route>
            </ErrorBoundary>
          </Switch>
          <Switch>
            <Route exact path="/registration/error">
              <RegistrationError analyticsManager={analyticsManager} />
            </Route>
            <Route exact path="/password-reset/error">
              <PasswordResetRequestError analyticsManager={analyticsManager} />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/error">
              <Auth0ErrorComponent
                analyticsManager={analyticsManager}
                history={history}
              />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/home">
              <Homepage />
            </Route>
            <Route exact path="/splash">
              <Splash />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/affiliate">
              <FederationEmailComponent />
            </Route>
          </Switch>
        </Content>
      </Menu>
    </div>
  );
};
