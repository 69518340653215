import axios from "axios";
import MockRegistrationService from "./mock-registration-service";
import EnvironmentFacade from "../../../environment/environment-facade";

export type UserRegistration = {
  requestSource: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  policyNumber: string;
};

export type RequestModel = {
  requestSource: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  policyNumber: string;
};

export interface IRegistrationService {
  submit(values: UserRegistration, requestSource: string): Promise<string>;
}

export function createModel(values: UserRegistration): RequestModel {
  const model: RequestModel = {
    requestSource: values.requestSource || "self-directed",
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    dob: new Date(values.dob).toISOString().slice(0, 10),
    policyNumber: values.policyNumber.replace(/-/g, ""),
  };
  return model;
}

class RegistrationService implements IRegistrationService {
  submit(values: UserRegistration): Promise<string> {
    const environment = EnvironmentFacade.getEnvironment();
    const model = createModel(values);

    if (environment.envName === "local") {
      return MockRegistrationService.submit(values);
    } else {
      return axios.post(`${environment.apiUrl}account`, model);
    }
  }
}

export default new RegistrationService();
