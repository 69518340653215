import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { RumEvent, datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import EnvironmentFacade from "./components/environment/environment-facade";

datadogRum.setGlobalContextProperty(
  "troux_uuid",
  "C4500E61-5775-4C26-B4D9-B98052DA055F",
);
datadogRum.setGlobalContextProperty("journey", "ijbap");

function removeEmailFromDatadogRumEvent(event: RumEvent) {
  if (event.type === "action") {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
    const redactEmailText = "[email address]";
    const actionName = event?.action?.target?.name;
    const viewUrl = event?.view?.url;

    if (actionName) {
      event.action.target.name = actionName.replace(
        emailRegex,
        redactEmailText,
      );
    }
    if (viewUrl) {
      event.view.url = viewUrl.replace(emailRegex, redactEmailText);
    }
  }
}

datadogRum.init({
  applicationId: "c969527e-3193-4764-91ed-2bb54ff22689",
  clientToken: "pubea5b05b300f212d2796ecf1d4d0fbd90",
  site: "datadoghq.com",
  service: "ijbap-ui-client",
  env:
    EnvironmentFacade.getEnvironment().envName === "production"
      ? "prod"
      : EnvironmentFacade.getEnvironment().envName === "non-prod"
        ? "nonprod"
        : "dev",
  version: "1.1.1",
  sampleRate: 100,
  sessionReplaySampleRate: 0,
  trackInteractions: true,
  defaultPrivacyLevel: "mask",
  beforeSend: (event) => removeEmailFromDatadogRumEvent(event),
});

datadogRum.startSessionReplayRecording();

datadogLogs.setLoggerGlobalContext({
  env:
    EnvironmentFacade.getEnvironment().envName === "production"
      ? "prod"
      : EnvironmentFacade.getEnvironment().envName === "non-prod"
        ? "nonprod"
        : "dev",
  journey: "ijbap",
  troux_uuid: "C4500E61-5775-4C26-B4D9-B98052DA055F",
});

datadogLogs.init({
  clientToken: "pubea5b05b300f212d2796ecf1d4d0fbd90",
  service: "ijbap-ui-client",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  version: "1.1.1",
});

document.title = "Liberty Mutual";
document.getElementsByTagName("meta")[3].content =
  "Shop for a variety of insurance at Liberty Mutual and get a free quote. Bundle your coverage to get discounts on auto, home, life, renters, motorcycle, and more.";

ReactDOM.render(
  // eslint-disable-next-line react/no-deprecated
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
